@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

input {
    &[type="submit"] {
        @include button();
    }
}

hr {
    max-width: none;
}

#flashMessenger {
    .callout {
        margin: 1rem 0 0 0;
    }
}

.ui-datepicker {
    background: $white;
    
    .ui-datepicker-header {
        text-align: center;
        padding: rem-calc(8 10); 
        .ui-datepicker-prev {
            display: block;
            float: left;
        }
        .ui-datepicker-next {
            display: block;
            float: right;
        }
    }
}

.chart
{
    canvas {
        height: rem-calc(300);
    }
    
    ul {
        list-style: none;
        
        i {
            margin: 0.2em 0.5em 0 0;
            border: 1px solid #ddd;
            display: inline-block;
            width: 0.8em;
            height: 0.8em;
        }
    }
}

.xhrLoader {
    position: relative;
    min-height: rem-calc(200);

    &::after {
        content: '';
        z-index: 10;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        background-image: url(../img/ajax-loader.gif);
        background-position: center center;
        background-repeat: no-repeat;
    }
}

table {
  font-size: 0.8em;
  width: 100%;

  .button {
    font-size: rem-calc(14);
    padding: rem-calc(4 12);
    margin-bottom: 0;
  }

  .button-group {
    float: right;
    font-size: 1em;
    margin-bottom: 0;
  }
  
  input, select {
      margin-bottom: 0;
  }
}

.button {
    &.square {
        width: 2.2em;
        height: 2.2em;
        padding: 0;
        text-align: center;
        line-height: 2.2em;
    }
}

ul.breadcrumbs {
  padding: rem-calc(3 3 3 6);
  border-radius: $global-radius;
  background-color: lighten($light-gray, 5%);
  border: 1px solid smart-scale(lighten($light-gray, 5%), 5%);
}